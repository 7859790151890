.post {
  padding: 2rem;
  position: relative;

  &__image {
    height: 45vh;
    background-image: url("../assets/images/aboutme.webp");
    background-position: center 15%;
    background-repeat: no-repeat;
    background-size: cover;

    @include device(desktop) {
      background-position: center 50%;
      height: 65vh;
    }

    @include device(lg-desktop) {
      background-position: center 30%;
      height: 65vh;
    }
  }

  &__heading {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: $primary-indigo;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__edit-btn {
    @extend .btn-indigo;
    top: 0.2rem;
    right: 8rem;
  }

  &__delete-btn {
    @extend .btn-indigo;
    background-color: #ffffff;
    color: $primary-indigo;
    border: 1px solid $primary-indigo;
    top: 0.2rem;
  }

  &__content {
    margin-bottom: 2rem;
    text-indent: 1rem;

    & p {
      margin-top: 1rem;
    }

    &::first-letter {
      font-size: 120%;
      color: $primary-indigo;
    }

    @include device(desktop) {
      font-size: 1.3rem;
    }
  }

  @include device(desktop) {
    max-width: $story-desktop;
    margin: auto;
  }
}

.course {
  &__content {
    overflow-x: hidden;
    margin-left: -16px !important;
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  &__btns {
    top: 6rem;
    z-index: 1000000;
  }
}