.login {
  @include flex-center;
  height: 100vh;
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__label {
    font-size: 1.6rem;
  }

  &__input {
    padding: 1rem;
    border: 2px solid $primary-purple;
    border-radius: 8px;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  &__button {
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid $primary-purple;
    padding: 1rem;
    width: 100%;
    &:hover,
    &:active {
      transform: scale(0.98);
    }
  }
}
