.contact {
  &__background {
    background-image: url('../assets/images/contact-hands.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 2rem;
  }

  &__content {
    margin: 2rem 1rem 2rem;
    background-color: rgba($primary-light, 0.85);
    border-radius: 25px;

    @include device(tablet-port) {
      padding: 2rem 3rem;
    }

    @include device(desktop) {
      max-width: $story-desktop;
      margin: 2rem auto;
    }
  }

  &__main-image {
    height: 25vh;
    background-image: url('../assets/images/contact.webp');
    background-position: center 15%;
    background-repeat: no-repeat;
    background-size: cover;

    @include device(desktop) {
      background-position: center 30%;
      height: 65vh;
    }
  }

  &__heading {
    text-align: center;
    color: $primary-indigo;
    padding: 1.5rem 2rem;
    box-shadow: 10px 2px 50px $primary-light;
    background-color: $white;
  }

  &__id {
    margin-left: 4rem;
    margin-top: 2rem;
    display: block;
    color: $primary-indigo;
  }

  &__personal {
    padding: 1rem;

    &-name {
      color: $primary-indigo;
      text-align: center;
      font-size: 1.8rem;
    }

    &-contact {
      @include flex-center;
      font-size: 1.2rem;
      color: $tertiary-grey;
      justify-content: start;
      gap: 2rem;
      margin-top: 1.5rem;
    }
  }

  &__links {
    padding: 1rem 1rem 4rem;

    &-heading {
      color: $primary-indigo;
      text-align: center;
      font-size: 1.8rem;
    }

    &-contact {
      @include flex-center;
      text-decoration: underline;
      font-size: 1.2rem;
      color: $tertiary-grey;
      justify-content: start;
      gap: 2rem;
      margin-top: 1.5rem;
    }
  }

  &__icon {
    font-size: 2rem;
    color: $primary-indigo;
  }

  &-credit {
    position: absolute;
    bottom: 0.1rem;
    right: 0.1rem;
    font-size: 0.7rem;
    color: #ccc;
  }
}