/* FONTS */
$font-primary: 'Radio Canada', sans-serif;
$font-secondary: 'Lora', serif;
$font-tertiary: 'Barlow', sans-serif;
$font-hand: 'Great Vibes', cursive;

/* COLORS */
$white: #fff;

$primary-light: #ccc;
$secondary-light: #bebebe;

$primary-grey: #555555;
$secondary-grey: #626262;
$tertiary-grey: #444242;

$primary-indigo: #60495a;
$secondary-indigo: #663366;

$primary-green: #49604f;
$secondary-green: #9fb6a5;

$primary-orange: #d45412;
$primary-purple: #85509b;
$primary-yellow: #f2c722;
$primary-blue: #211ed5;

$header-height: 5rem;
$story-desktop: 60vw;

$test: green;
