.services {
  padding: 2rem;

  &__main-image {
    height: 45vh;
    background-image: url('../assets/images/services.webp');
    background-position: center 15%;
    background-repeat: no-repeat;
    background-size: cover;
    @include device(desktop) {
      background-position: center 42%;
      height: 65vh;
    }
  }

  &__heading {
    h2 {
      font-size: 3rem;
      margin-bottom: 2rem;
      color: $primary-indigo;
    }
  }

  &__item {
    margin-bottom: 3.5rem;

    &-heading {
      position: relative;
      color: $primary-indigo;
      font-size: 1.3rem;
      padding: 0 0.5rem 0.5rem;

      &::after {
        @include underline($primary-indigo);
        left: 0;
        max-width: 65vw;
      }
    }

    &-description {
      margin-top: 0.5rem;
      line-height: 150%;
      text-indent: 1rem;
    }
    &-bold {
      display: block;
      font-weight: 700;
    }

    &-price {
      margin-top: 1.2rem;
      color: $primary-grey;
      font-weight: 800;
      display: block;
      text-align: end;
    }

    &-link {
      font-size: 1.1rem;
      font-weight: 700;
      color: $primary-indigo;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  @include device(desktop) {
    max-width: $story-desktop;
    margin: auto;
    font-size: 120%;
  }
}
