.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: $header-height;
  background-color: rgba($primary-indigo, 0.8);
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  overflow-x: hidden;
  z-index: 1000;

  &__logo {
    border: 3px solid $white;
    width: fit-content;
    padding: 0.2rem 0.5rem;

    &-text {
      font-size: 1.75rem;
      color: $white;
      font-weight: 300;
      font-family: $font-primary;
    }
  }

  &__burger {
    font-size: 2rem;
    color: $white;
    font-family: $font-primary;
    cursor: pointer;

    @include device(desktop) {
      display: none;
    }
  }

  &__navbar {
    position: fixed;
    display: flex;
    align-items: start;
    justify-content: end;
    top: $header-height;
    right: 0;
    padding-right: 1rem;
    background-color: $secondary-grey;
    color: $white;
    width: 80vw;
    height: calc(100vh - $header-height);
    z-index: 1000;

    opacity: 0;
    transform: translateX(100%);

    transition: transform 0.3s ease-in, opacity 0.5s linear;

    &.header-open {
      opacity: 1;
      transform: translateX(0%);
      transition: transform 0.3s ease-in, opacity 0.5s linear;
    }
    @include device(tablet-port) {
      width: 45vw;
    }
    @include device(desktop) {
      height: auto;
      width: auto;
      position: initial;
      top: 0;
      transform: translateX(0%);
      opacity: 1;
      background-color: transparent;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: end;

    @include device(desktop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__item {
    position: relative;
    list-style: none;
    margin-top: 1.5rem;
    border-bottom: 1px solid rgba($primary-indigo, 0.8);
    width: 100%;
    font-size: 1.5rem;
    font-family: $font-primary;

    &::after {
      @include underline($white);
      width: 0;
    }

    &:hover::after {
      width: calc(100% + 5px);
    }

    @include device(desktop) {
      border: none;
      margin-top: 0;
      width: auto;
    }
  }

  &__link {
    display: inline-block;
    width: 100%;
  }

  @include device(desktop) {
    padding: 0 2rem;
  }

  @include device(lg-desktop) {
    padding: 0 10rem;
  }
}
