.btn-indigo {
  cursor: pointer;
  color: $white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  background-color: $primary-indigo;
  border-radius: 25rem;
  padding: 0.5rem 1rem;

  &:hover,
  &:active {
    transform: scale(98%);
  }
}
