.aboutme {
  padding: 2rem;

  &__main-image {
    height: 45vh;
    background-image: url('../assets/images/aboutme.webp');
    background-position: center 15%;
    background-repeat: no-repeat;
    background-size: cover;

    @include device(desktop) {
      background-position: center 30%;
      height: 65vh;
    }
  }

  &__heading {
    h2 {
      font-size: 3rem;
      margin-bottom: 2rem;
      color: $primary-indigo;
    }
  }

  &__story {
    &-paragraph {
      margin-bottom: 2rem;
      text-indent: 1rem;

      &::first-letter {
        font-size: 120%;
        color: $primary-indigo;
      }

      @include device(desktop) {
        font-size: 1.3rem;
      }

      &-courses {
        @extend .aboutme__story-paragraph;

        span {
          display: block;
          text-align: right;
        }
      }
    }

    &-statement {
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 1.5rem;
      font-style: italic;
      padding: 2rem 0 4rem;
      text-indent: 1rem;

      @include device(desktop) {
        padding: 0.6rem 0 4rem;
        font-size: 1.8rem;
        text-align: end;
      }
    }
  }

  @include device(desktop) {
    max-width: $story-desktop;
    margin: auto;
  }
}