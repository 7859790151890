.socionika {
  padding: 2rem;

  &__image {
    height: 45vh;
    background-image: url("../assets/images/socionika.webp");
    background-position: center 15%;
    background-repeat: no-repeat;
    background-size: cover;

    @include device(desktop) {
      background-position: center 25%;
      height: 65vh;
    }
  }

  &__paragraph {
    margin-bottom: 2rem;
    text-indent: 1rem;

    &::first-letter {
      font-size: 120%;
      color: $primary-indigo;
    }

    @include device(desktop) {
      font-size: 1.3rem;
    }
  }

  &__list {
    margin: 0 auto;

    span {
      display: block;
      margin-bottom: 1rem
    }

    svg {
      margin-right: 1rem;
    }

    ul {
      @include device(desktop) {
        padding-left: 4rem;
      }
    }

    @include device(desktop) {
      font-size: 1.3rem;
    }

    &-long {
      span {
        margin-top: 1rem;
      }

      ul {
        margin-bottom: 2rem;

        @include device(desktop) {
          padding-left: 2rem;
        }
      }

      li {
        margin-bottom: 1rem;
      }
    }
  }

  &__types {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-indent: 1rem;

    &::first-letter {
      font-size: 120%;
      color: $primary-indigo;
    }

    &-heading {
      color: $primary-indigo;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2rem;
      text-decoration: underline;
    }

    &-groups {}

    &-group {
      border: 5px dashed #f1f1f1;
      border-radius: 8px;
      margin-bottom: 2rem;

      &-description {
        color: #ffffff;
        border-radius: 8px;
        background-color: $primary-indigo;
        text-align: center;
        margin-bottom: 1rem;
        padding: 1rem;
      }

      &-items {
        padding: 1rem;
        margin-bottom: 2rem;

        @include device(phone) {
          padding: 1rem 0;
        }
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        margin-bottom: 1rem;

        &-img {
          height: 10rem;
          width: 5rem;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        &-text {
          @include device(tablet-port) {
            display: flex;
            font-size: 1.6rem;
          }
        }

        &-name {
          border-bottom: 2px solid #f1f1f1;


          @include device(tablet-port) {
            border-bottom: none;
          }
        }

        &:nth-of-type(even) {
          & .socionika__types-group-item-img {
            order: 3;
          }

        }

        &:nth-of-type(odd) {
          & .socionika__types-group-item-name {
            text-align: right;
          }
        }

        @include device(desktop) {
          padding: 0 3rem;
        }
      }
    }

    @include device(desktop) {
      font-size: 1.3rem;
    }
  }

  @include device(desktop) {
    max-width: $story-desktop;
    margin: auto;
  }
}