*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

body {
  position: relative;
  box-sizing: border-box;
  font-family: var(--font-primary);
  min-height: 100vh;
  overflow-x: hidden;
  z-index: -1000;
  font-family: $font-primary;
}

button {
  font-family: inherit;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

li {
  list-style-type: none;
}

a {
  font-family: inherit;
  text-decoration: none;
  color: inherit;
}
