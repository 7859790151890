/* FONTS */
/* COLORS */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

body {
  position: relative;
  box-sizing: border-box;
  font-family: var(--font-primary);
  min-height: 100vh;
  overflow-x: hidden;
  z-index: -1000;
  font-family: "Radio Canada", sans-serif;
}

button {
  font-family: inherit;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

li {
  list-style-type: none;
}

a {
  font-family: inherit;
  text-decoration: none;
  color: inherit;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: rgba(96, 73, 90, 0.8);
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  overflow-x: hidden;
  z-index: 1000;
}
.header__logo {
  border: 3px solid #fff;
  width: fit-content;
  padding: 0.2rem 0.5rem;
}
.header__logo-text {
  font-size: 1.75rem;
  color: #fff;
  font-weight: 300;
  font-family: "Radio Canada", sans-serif;
}
.header__burger {
  font-size: 2rem;
  color: #fff;
  font-family: "Radio Canada", sans-serif;
  cursor: pointer;
}
@media only screen and (min-width: 66em) {
  .header__burger {
    display: none;
  }
}
.header__navbar {
  position: fixed;
  display: flex;
  align-items: start;
  justify-content: end;
  top: 5rem;
  right: 0;
  padding-right: 1rem;
  background-color: #626262;
  color: #fff;
  width: 80vw;
  height: calc(100vh - 5rem);
  z-index: 1000;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in, opacity 0.5s linear;
}
.header__navbar.header-open {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 0.3s ease-in, opacity 0.5s linear;
}
@media only screen and (min-width: 37.5em) {
  .header__navbar {
    width: 45vw;
  }
}
@media only screen and (min-width: 66em) {
  .header__navbar {
    height: auto;
    width: auto;
    position: initial;
    top: 0;
    transform: translateX(0%);
    opacity: 1;
    background-color: transparent;
  }
}
.header__items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;
}
@media only screen and (min-width: 66em) {
  .header__items {
    flex-direction: row;
    align-items: center;
  }
}
.header__item {
  position: relative;
  list-style: none;
  margin-top: 1.5rem;
  border-bottom: 1px solid rgba(96, 73, 90, 0.8);
  width: 100%;
  font-size: 1.5rem;
  font-family: "Radio Canada", sans-serif;
}
.header__item::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -5px;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: width 0.2s ease-in;
  width: 0;
}
.header__item:hover::after {
  width: calc(100% + 5px);
}
@media only screen and (min-width: 66em) {
  .header__item {
    border: none;
    margin-top: 0;
    width: auto;
  }
}
.header__link {
  display: inline-block;
  width: 100%;
}
@media only screen and (min-width: 66em) {
  .header {
    padding: 0 2rem;
  }
}
@media only screen and (min-width: 85em) {
  .header {
    padding: 0 10rem;
  }
}

.content-container {
  padding-top: 5rem;
}

.btn-indigo, .editpost__button, .addpost__button, .post__delete-btn, .post__edit-btn {
  cursor: pointer;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  background-color: #60495a;
  border-radius: 25rem;
  padding: 0.5rem 1rem;
}
.btn-indigo:hover, .editpost__button:hover, .addpost__button:hover, .post__delete-btn:hover, .post__edit-btn:hover, .btn-indigo:active, .editpost__button:active, .addpost__button:active, .post__delete-btn:active, .post__edit-btn:active {
  transform: scale(98%);
}

.home__section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: start;
  min-height: 100vh;
}
.home__hero {
  position: relative;
  background-image: url("../assets/images/hero.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 0;
}
@supports not (-webkit-touch-callout: none) {
  .home__hero {
    background-attachment: fixed;
  }
}
.home__hero-heading {
  opacity: 0;
  height: 5px;
}
.home__hero-testimonial {
  text-align: center;
  font-weight: 400;
  padding: 2rem;
}
.home__hero-testimonial--paragraph {
  font-family: "Barlow", sans-serif;
  color: #444242;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: -1rem;
}
@media only screen and (min-width: 66em) {
  .home__hero-testimonial--paragraph {
    font-size: 1.5rem;
    max-width: 65vw;
  }
}
.home__hero-testimonial--subheading {
  font-family: "Barlow", sans-serif;
  color: #444242;
  font-weight: 400;
  font-size: 3rem;
  font-style: italic;
  margin-top: 1rem;
}
@media only screen and (min-width: 66em) {
  .home__hero-testimonial--subheading {
    font-size: 4rem;
    max-width: 65vw;
    letter-spacing: 3px;
  }
}
@media only screen and (max-height: 350px) {
  .home__hero-testimonial--subheading {
    font-size: 2rem;
    display: none;
  }
}
.home__hero-testimonial--intro {
  font-family: "Barlow", sans-serif;
  color: #444242;
  font-weight: 400;
  font-size: 1.5rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 12px;
  margin: 1.5rem auto 0;
}
.home__hero-testimonial--intro span {
  display: block;
  font-weight: 900;
}
@media only screen and (min-width: 66em) {
  .home__hero-testimonial--intro {
    font-size: 1.5rem;
    max-width: 50vw;
  }
}
.home__hero-credit {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  font-size: 0.7rem;
  color: #bababa;
}
@media only screen and (min-width: 48em) {
  .home__hero {
    padding-top: 7rem;
  }
}
.home__headline {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #60495a;
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  height: 35vh;
}
.home__headline-statement {
  text-align: center;
  font-family: "Great Vibes", cursive;
  letter-spacing: 0.2rem;
}
@media only screen and (min-width: 66em) {
  .home__headline-statement {
    display: block;
    font-size: 5rem;
    margin-left: -35vw;
    width: min-content;
    text-align: center;
  }
}
.home__headline-statement span {
  font-size: 200%;
}
@media only screen and (min-width: 66em) {
  .home__headline {
    background-image: url("../assets/images/hero-zuza.webp");
    background-position: 80% 65%;
    background-size: 40%;
    background-repeat: no-repeat;
    height: 80vh;
    gap: 2rem;
  }
}
.home__photo {
  background-image: url("../assets/images/hero-zuza.webp");
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10rem;
  height: 100vh;
}
@supports not (-webkit-touch-callout: none) {
  .home__photo {
    background-attachment: fixed;
  }
}
@media only screen and (min-width: 37.5em) {
  .home__photo {
    background-position: center 5%;
  }
}
@media only screen and (min-width: 66em) {
  .home__photo {
    display: none;
  }
}
.home__photo-paragraph {
  background-color: rgba(255, 255, 255, 0.8);
  margin: 5rem 1rem 0;
  padding: 1.5rem;
  border-radius: 25px;
}
@media only screen and (min-width: 37.5em) {
  .home__photo-paragraph {
    margin: 5rem 7rem 0;
  }
}
@media only screen and (min-width: 66em) {
  .home__photo-paragraph {
    font-size: 1.3rem;
  }
}

.aboutme {
  padding: 2rem;
}
.aboutme__main-image {
  height: 45vh;
  background-image: url("../assets/images/aboutme.webp");
  background-position: center 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 66em) {
  .aboutme__main-image {
    background-position: center 30%;
    height: 65vh;
  }
}
.aboutme__heading h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #60495a;
}
.aboutme__story-paragraph, .aboutme__story-paragraph-courses {
  margin-bottom: 2rem;
  text-indent: 1rem;
}
.aboutme__story-paragraph::first-letter, .aboutme__story-paragraph-courses::first-letter {
  font-size: 120%;
  color: #60495a;
}
@media only screen and (min-width: 66em) {
  .aboutme__story-paragraph, .aboutme__story-paragraph-courses {
    font-size: 1.3rem;
  }
}
.aboutme__story-paragraph-courses span {
  display: block;
  text-align: right;
}
.aboutme__story-statement {
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 1.5rem;
  font-style: italic;
  padding: 2rem 0 4rem;
  text-indent: 1rem;
}
@media only screen and (min-width: 66em) {
  .aboutme__story-statement {
    padding: 0.6rem 0 4rem;
    font-size: 1.8rem;
    text-align: end;
  }
}
@media only screen and (min-width: 66em) {
  .aboutme {
    max-width: 60vw;
    margin: auto;
  }
}

.services {
  padding: 2rem;
}
.services__main-image {
  height: 45vh;
  background-image: url("../assets/images/services.webp");
  background-position: center 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 66em) {
  .services__main-image {
    background-position: center 42%;
    height: 65vh;
  }
}
.services__heading h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #60495a;
}
.services__item {
  margin-bottom: 3.5rem;
}
.services__item-heading {
  position: relative;
  color: #60495a;
  font-size: 1.3rem;
  padding: 0 0.5rem 0.5rem;
}
.services__item-heading::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -5px;
  width: 100%;
  height: 3px;
  background-color: #60495a;
  transition: width 0.2s ease-in;
  left: 0;
  max-width: 65vw;
}
.services__item-description {
  margin-top: 0.5rem;
  line-height: 150%;
  text-indent: 1rem;
}
.services__item-bold {
  display: block;
  font-weight: 700;
}
.services__item-price {
  margin-top: 1.2rem;
  color: #555555;
  font-weight: 800;
  display: block;
  text-align: end;
}
.services__item-link {
  font-size: 1.1rem;
  font-weight: 700;
  color: #60495a;
}
.services__item-link:hover, .services__item-link:active {
  text-decoration: underline;
}
@media only screen and (min-width: 66em) {
  .services {
    max-width: 60vw;
    margin: auto;
    font-size: 120%;
  }
}

.socionika {
  padding: 2rem;
}
.socionika__image {
  height: 45vh;
  background-image: url("../assets/images/socionika.webp");
  background-position: center 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 66em) {
  .socionika__image {
    background-position: center 25%;
    height: 65vh;
  }
}
.socionika__paragraph {
  margin-bottom: 2rem;
  text-indent: 1rem;
}
.socionika__paragraph::first-letter {
  font-size: 120%;
  color: #60495a;
}
@media only screen and (min-width: 66em) {
  .socionika__paragraph {
    font-size: 1.3rem;
  }
}
.socionika__list {
  margin: 0 auto;
}
.socionika__list span {
  display: block;
  margin-bottom: 1rem;
}
.socionika__list svg {
  margin-right: 1rem;
}
@media only screen and (min-width: 66em) {
  .socionika__list ul {
    padding-left: 4rem;
  }
}
@media only screen and (min-width: 66em) {
  .socionika__list {
    font-size: 1.3rem;
  }
}
.socionika__list-long span {
  margin-top: 1rem;
}
.socionika__list-long ul {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 66em) {
  .socionika__list-long ul {
    padding-left: 2rem;
  }
}
.socionika__list-long li {
  margin-bottom: 1rem;
}
.socionika__types {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-indent: 1rem;
}
.socionika__types::first-letter {
  font-size: 120%;
  color: #60495a;
}
.socionika__types-heading {
  color: #60495a;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  text-decoration: underline;
}
.socionika__types-group {
  border: 5px dashed #f1f1f1;
  border-radius: 8px;
  margin-bottom: 2rem;
}
.socionika__types-group-description {
  color: #ffffff;
  border-radius: 8px;
  background-color: #60495a;
  text-align: center;
  margin-bottom: 1rem;
  padding: 1rem;
}
.socionika__types-group-items {
  padding: 1rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .socionika__types-group-items {
    padding: 1rem 0;
  }
}
.socionika__types-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
}
.socionika__types-group-item-img {
  height: 10rem;
  width: 5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media only screen and (min-width: 37.5em) {
  .socionika__types-group-item-text {
    display: flex;
    font-size: 1.6rem;
  }
}
.socionika__types-group-item-name {
  border-bottom: 2px solid #f1f1f1;
}
@media only screen and (min-width: 37.5em) {
  .socionika__types-group-item-name {
    border-bottom: none;
  }
}
.socionika__types-group-item:nth-of-type(even) .socionika__types-group-item-img {
  order: 3;
}
.socionika__types-group-item:nth-of-type(odd) .socionika__types-group-item-name {
  text-align: right;
}
@media only screen and (min-width: 66em) {
  .socionika__types-group-item {
    padding: 0 3rem;
  }
}
@media only screen and (min-width: 66em) {
  .socionika__types {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 66em) {
  .socionika {
    max-width: 60vw;
    margin: auto;
  }
}

.blog__thumbnails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
}
@media only screen and (min-width: 37.5em) {
  .blog__thumbnails {
    margin: auto;
    gap: 2rem;
    width: 80vw;
  }
}
.blog__thumbnail {
  width: 100%;
  max-width: 20rem;
  height: 15rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(85, 85, 85, 0.3);
  box-shadow: 5px 5px 5px rgba(85, 85, 85, 0.3);
  transition: all 0.2s ease-in;
}
.blog__thumbnail-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80%;
}
.blog__thumbnail-title {
  display: inline-block;
  display: flex;
  align-items: center;
  color: #60495a;
  height: 20%;
  margin-left: 1rem;
}
.blog__thumbnail-heading::first-letter {
  text-transform: uppercase;
}
.blog__thumbnail:hover, .blog__thumbnail:active {
  cursor: pointer;
  transform: scale(104%);
  box-shadow: 8px 8px 11px rgba(85, 85, 85, 0.6);
}

.post {
  padding: 2rem;
  position: relative;
}
.post__image {
  height: 45vh;
  background-image: url("../assets/images/aboutme.webp");
  background-position: center 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 66em) {
  .post__image {
    background-position: center 50%;
    height: 65vh;
  }
}
@media only screen and (min-width: 85em) {
  .post__image {
    background-position: center 30%;
    height: 65vh;
  }
}
.post__heading {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #60495a;
}
.post__heading::first-letter {
  text-transform: capitalize;
}
.post__edit-btn {
  top: 0.2rem;
  right: 8rem;
}
.post__delete-btn {
  background-color: #ffffff;
  color: #60495a;
  border: 1px solid #60495a;
  top: 0.2rem;
}
.post__content {
  margin-bottom: 2rem;
  text-indent: 1rem;
}
.post__content p {
  margin-top: 1rem;
}
.post__content::first-letter {
  font-size: 120%;
  color: #60495a;
}
@media only screen and (min-width: 66em) {
  .post__content {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 66em) {
  .post {
    max-width: 60vw;
    margin: auto;
  }
}

.course__content {
  overflow-x: hidden;
  margin-left: -16px !important;
  width: 100vw;
  margin: 0;
  padding: 0;
}
.course__btns {
  top: 6rem;
  z-index: 1000000;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login__label {
  font-size: 1.6rem;
}
.login__input {
  padding: 1rem;
  border: 2px solid #85509b;
  border-radius: 8px;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.login__button {
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #85509b;
  padding: 1rem;
  width: 100%;
}
.login__button:hover, .login__button:active {
  transform: scale(0.98);
}

.addpost {
  position: relative;
  width: 60vw;
  margin: auto;
}
.addpost__heading {
  margin: 2rem 0 2rem 0;
  color: #60495a;
}
.addpost__button {
  right: 5px;
}
.addpost__input {
  border-radius: 5px;
  border: 1px solid rgba(96, 73, 90, 0.8);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 5rem;
  font-size: 1.5rem;
  color: #60495a;
}
.addpost__editor {
  height: 50vh;
}

.editpost {
  position: relative;
  width: 60vw;
  margin: auto;
}
.editpost__heading {
  margin: 2rem 0 2rem 0;
  color: #60495a;
}
.editpost__button {
  right: 5px;
}
.editpost__input {
  border-radius: 5px;
  border: 1px solid rgba(96, 73, 90, 0.8);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 5rem;
  font-size: 1.5rem;
  color: #60495a;
}
.editpost__input#edit-title {
  width: 100%;
  margin-right: 0;
}
.editpost__editor {
  height: 50vh;
}

.contact__background {
  background-image: url("../assets/images/contact-hands.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 2rem;
}
.contact__content {
  margin: 2rem 1rem 2rem;
  background-color: rgba(204, 204, 204, 0.85);
  border-radius: 25px;
}
@media only screen and (min-width: 37.5em) {
  .contact__content {
    padding: 2rem 3rem;
  }
}
@media only screen and (min-width: 66em) {
  .contact__content {
    max-width: 60vw;
    margin: 2rem auto;
  }
}
.contact__main-image {
  height: 25vh;
  background-image: url("../assets/images/contact.webp");
  background-position: center 15%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 66em) {
  .contact__main-image {
    background-position: center 30%;
    height: 65vh;
  }
}
.contact__heading {
  text-align: center;
  color: #60495a;
  padding: 1.5rem 2rem;
  box-shadow: 10px 2px 50px #ccc;
  background-color: #fff;
}
.contact__id {
  margin-left: 4rem;
  margin-top: 2rem;
  display: block;
  color: #60495a;
}
.contact__personal {
  padding: 1rem;
}
.contact__personal-name {
  color: #60495a;
  text-align: center;
  font-size: 1.8rem;
}
.contact__personal-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #444242;
  justify-content: start;
  gap: 2rem;
  margin-top: 1.5rem;
}
.contact__links {
  padding: 1rem 1rem 4rem;
}
.contact__links-heading {
  color: #60495a;
  text-align: center;
  font-size: 1.8rem;
}
.contact__links-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  font-size: 1.2rem;
  color: #444242;
  justify-content: start;
  gap: 2rem;
  margin-top: 1.5rem;
}
.contact__icon {
  font-size: 2rem;
  color: #60495a;
}
.contact-credit {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  font-size: 0.7rem;
  color: #ccc;
}

