.home {
  &__section {
    @include flex-center;
    align-items: start;
    min-height: 100vh;
  }

  &__hero {
    position: relative;
    background-image: url("../assets/images/hero.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 0;

    @supports not (-webkit-touch-callout: none) {
      background-attachment: fixed;
    }

    &-heading {
      opacity: 0;
      height: 5px;
    }

    &-testimonial {
      text-align: center;
      font-weight: 400;
      padding: 2rem;


      &--paragraph {
        font-family: $font-tertiary;
        color: $tertiary-grey;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 2rem;
        margin-bottom: -1rem;

        @include device(desktop) {
          font-size: 1.5rem;
          max-width: 65vw;
        }
      }

      &--subheading {
        font-family: $font-tertiary;
        color: $tertiary-grey;
        font-weight: 400;
        font-size: 3rem;
        font-style: italic;
        margin-top: 1rem;


        @include device(desktop) {
          font-size: 4rem;
          max-width: 65vw;
          letter-spacing: 3px;
        }

        @media only screen and (max-height: 350px) {
          font-size: 2rem;
          display: none;
        }
      }

      &--intro {
        font-family: $font-tertiary;
        color: $tertiary-grey;
        font-weight: 400;
        font-size: 1.5rem;
        border-radius: 8px;
        background-color: #ffffffcc;
        padding: 12px;
        margin: 1.5rem auto 0;

        span {
          display: block;
          font-weight: 900;
        }

        @include device(desktop) {
          font-size: 1.5rem;
          max-width: 50vw;
        }
      }
    }

    &-credit {
      position: absolute;
      bottom: 0.1rem;
      right: 0.1rem;
      font-size: 0.7rem;
      color: #bababa;
    }


    @include device(tablet-land) {
      padding-top: 7rem
    }
  }

  &__headline {
    @include flex-center;
    color: $primary-indigo;
    font-family: $font-tertiary;
    font-size: 1.5rem;
    height: 35vh;

    &-statement {
      text-align: center;
      font-family: $font-hand;
      letter-spacing: 0.2rem;

      @include device(desktop) {
        display: block;
        font-size: 5rem;
        margin-left: -35vw;
        width: min-content;
        text-align: center;
      }

      & span {
        font-size: 200%;
      }
    }

    @include device(desktop) {
      background-image: url("../assets/images/hero-zuza.webp");
      background-position: 80% 65%;
      background-size: 40%;
      background-repeat: no-repeat;
      height: 80vh;
      gap: 2rem;
    }
  }

  &__photo {
    background-image: url("../assets/images/hero-zuza.webp");
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
    height: 100vh;

    @supports not (-webkit-touch-callout: none) {
      background-attachment: fixed;
    }

    @include device(tablet-port) {
      background-position: center 5%;
    }

    @include device(desktop) {
      display: none;
    }

    &-paragraph {
      background-color: rgba($white, 0.8);
      margin: 5rem 1rem 0;
      padding: 1.5rem;
      border-radius: 25px;

      @include device(tablet-port) {
        margin: 5rem 7rem 0;
      }

      @include device(desktop) {
        font-size: 1.3rem;
      }
    }
  }
}