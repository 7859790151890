.editpost {
  position: relative;
  width: $story-desktop;
  margin: auto;

  &__heading {
    margin: 2rem 0 2rem 0;
    color: $primary-indigo;
  }

  &__button {
    @extend .btn-indigo;
    right: 5px;
  }
  &__input {
    border-radius: 5px;
    border: 1px solid rgba($primary-indigo, $alpha: 0.8);
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 5rem;
    font-size: 1.5rem;
    color: $primary-indigo;

    &#edit-title {
      width: 100%;
      margin-right: 0;
    }
  }

  &__editor {
    height: 50vh;
  }
}
