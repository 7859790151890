.blog {
  &__thumbnails {
    @include flex-center;
    flex-wrap: wrap;
    padding: 2rem;

    @include device(tablet-port) {
      margin: auto;
      gap: 2rem;
      width: 80vw;
    }
  }

  &__thumbnail {
    width: 100%;
    max-width: 20rem;
    height: 15rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba($primary-grey, $alpha: 0.3);
    box-shadow: 5px 5px 5px rgba($primary-grey, $alpha: 0.3);
    transition: all 0.2s ease-in;

    @include device(tablet-port) {
    }

    &-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 80%;
    }

    &-title {
      display: inline-block;
      display: flex;
      align-items: center;
      color: $primary-indigo;
      height: 20%;
      margin-left: 1rem;
    }

    &-heading::first-letter {
      text-transform: uppercase;
    }

    &:hover,
    &:active {
      cursor: pointer;
      transform: scale(104%);
      box-shadow: 8px 8px 11px rgba($primary-grey, $alpha: 0.6);
    }
  }
}
